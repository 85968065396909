import { useCalculationsContext } from "../CalculationsContext"
import styles from "./Tile.module.scss"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileValue } from "./TileValue"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"
import { round } from "src/utils"

interface Props {
  range: InsightRange;
}

export function GlucoseManagementIndicator(props: Props) {
  const { range } = props

  const { glucoseCalculations } = useCalculationsContext()
  const { gmi } = glucoseCalculations[range] || {}

  const value = isNaN(gmi) || gmi === undefined
    ? "--"
    : round(
      gmi,
      1,
    ).toLocaleString(navigator.language)

  return (
    <>
      <TileTitle insightKey={InsightKey.GlucoseManagementIndicator} />
      <TileTimeSpan
        id={`${InsightKey.GlucoseManagementIndicator}-time-range`}
        range={range}
      />
      <TileValue id={`${InsightKey.GlucoseManagementIndicator}-value`}>
        {`${value}`}
        <span className={styles.valuePercentSymbol}
          id={`${InsightKey.GlucoseManagementIndicator}-percent`}
        >
          %
        </span>
      </TileValue>
    </>
  )
}
