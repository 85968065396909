import {
  Dropdown,
  DropdownCaret,
  DropdownCurrentSelection,
  DropdownOption,
  DropdownSelect,
  InfoButton,
  Label,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import {
  useCallback,
  useMemo,
} from "react"

import styles from "./AccountMedicationModal.module.scss"
import type { EditableAccountMedication } from "./EditableAccountMedication"
import { emptyAccountMedication } from "./EditableAccountMedication"
import { usePolyglot } from "src/contexts"
import { useMedicationCategories } from "src/hooks"

interface Props {
  currentAccountMedication: EditableAccountMedication;
  editAccountMedication: (e: EditableAccountMedication) => void;
}

interface Option {
  value: string;
  children: string;
  disabled?: boolean;
}

const id = "medication-category-picker"
const polyglotPrefix = "components.account_medication_modal.category."

function categoryToPhraseKey(category: string) {
  return category
    .toLowerCase()
    .replace(
      /[^a-z0-9 ]/g,
      "",
    )
    .replace(
      /[ ]+/g,
      "_",
    )
}

/*
 * renders a select element for choosing medication category
 */
export function MedicationCategorySelect(props: Props) {
  const {
    currentAccountMedication,
    editAccountMedication,
  } = props

  const polyglot = usePolyglot()

  // alphabetized medication categories
  const medicationCategories = useMedicationCategories()

  // the options to present in the select menu
  const options: Array<Option> = useMemo(
    () => {
      // the placeholder option
      const defaultOption: Option = {
        value: emptyAccountMedication.med.category,
        children: polyglot.t(`${polyglotPrefix}placeholder`),
        disabled: true,
      }

      // add each medication category to the options menu
      const categoryOptions = medicationCategories.map((category): Option => {
        // TODO in the future phrase keys should be based on the category field
        const phraseKey = `${polyglotPrefix}${categoryToPhraseKey(category)}`

        return {
          value: category,
          children: polyglot.has(phraseKey) ? polyglot.t(phraseKey) : category,
        }
      })

      // sort alphabetically according to display string and locale
      categoryOptions.sort((optionA, optionB) => optionA.children.localeCompare(
        optionB.children,
        navigator?.language,
      ))

      return [
        defaultOption,
        ...categoryOptions,
      ]
    },
    [medicationCategories],
  )

  // update the currentAccountMedication's category
  const onCategoryChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      const category = e.target.value

      // update category and clear med id
      editAccountMedication({
        ...currentAccountMedication,
        med_id: emptyAccountMedication.med_id,
        med: {
          ...currentAccountMedication.med,
          category,
        },
      })
    },
    [
      currentAccountMedication,
      editAccountMedication,
    ],
  )

  return (
    <div>
      <Label htmlFor={id}
        sizePreset="small"
        className={styles.label}
        id="medication-category-label"
      >
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}info_title`)}>
          {polyglot.t(`${polyglotPrefix}info_description`)}
        </InfoButton>
      </Label>
      <Dropdown>
        <DropdownSelect
          id={id}
          value={currentAccountMedication.med?.category ?? ""}
          onChange={onCategoryChange}
          required
        >
          {options.map(
            (selectOption) => (
              <DropdownOption
                key={selectOption.value}
                {...selectOption}
              />
            ),
          )}
        </DropdownSelect>
        <DropdownCurrentSelection />
        <DropdownCaret />
      </Dropdown>
    </div>
  )
}
