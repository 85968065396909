import { useCalculationsContext } from "../CalculationsContext"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileUnit } from "./TileUnit"
import { TileValue } from "./TileValue"
import { usePolyglot } from "src/contexts"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"
import { round } from "src/utils"

interface Props {
  range: InsightRange;
}

export function FoodAverageProtein(props: Props) {
  const { range } = props

  const polyglot = usePolyglot()
  const days = range / 24

  const { foodCalculations } = useCalculationsContext()
  const { totalProtein } = foodCalculations[range] || {}

  const value = totalProtein === undefined ?
    NaN :
    round(
      totalProtein / days,
      1,
    )

  const unit = polyglot.t(
    "unit.grams_day",
    isNaN(value) ? 0 : value,
  )

  return (
    <>
      <TileTitle insightKey={InsightKey.FoodAverageProtein} />
      <TileTimeSpan
        id={`${InsightKey.FoodAverageProtein}-time-range`}
        range={range}
      />
      <TileValue id={`${InsightKey.FoodAverageProtein}-value`}>
        {isNaN(value) ? "--" : value.toLocaleString(navigator.language)}
      </TileValue>
      <TileUnit id={`${InsightKey.FoodAverageProtein}-unit`}>
        {unit}
      </TileUnit>
    </>
  )
}
