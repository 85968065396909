import {
  Dialog,
  DialogCloseX,
  DialogButton,
  DialogButtons,
  DialogContent,
  DialogHeading,
  DialogTrigger,
  PopoverMenuItem,
  PopoverMenuItemIcon,
  PopoverMenuItemSeparator,
  CopyOutlineIcon,
  usePopoverContext,
  useToasterContext,
} from "@tandemdiabetes/sugarmate-react-components"
import {
  useCallback,
  useState,
} from "react"
import { useEntryModalPrivateContext } from "../EntryModalPrivateContext"
import { useEntryModalPublicContext } from "../EntryModalPublicContext"
import { DiscardChangesDialog } from "src/components/DiscardChangesDialog"
import { usePolyglot } from "src/contexts"
import {
  DuplicateEntryErrorToast,
  DuplicateEntrySuccessToast,
} from "src/toasts"

const polyglotPrefix = "components.entry_modal.entry_context_menu."

export function DuplicateEntry() {
  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const { duplicateEntry } = useEntryModalPrivateContext()
  const {
    openEntryModal,
    hasUnsavedChanges,
  } = useEntryModalPublicContext()

  const { setOpen: setPopoverOpen } = usePopoverContext()

  const [
    discardChangesOpen,
    setDiscardChangesOpen,
  ] = useState<boolean>(false)

  const openDiscardChangesDialog = useCallback(
    () => {
      setDiscardChangesOpen(true)
    },
    [setDiscardChangesOpen],
  )

  const duplicateEntryCallback = useCallback(
    () => {
      void (async () => {
        try {
          const newEntry = await duplicateEntry()
          openEntryModal({
            userEntry: newEntry,
            subEntry: newEntry.subentries.length === 1 ? newEntry.subentries[0] : undefined,
          })

          showToast(
            "duplicate-entry-success-toast",
            DuplicateEntrySuccessToast,
          )
        } catch (e) {
          showToast(
            "duplicate-entry-error-toast",
            DuplicateEntryErrorToast,
          )
        }

        setPopoverOpen(false)
      })()
    },
    [
      showToast,
      openEntryModal,
      duplicateEntry,
      setPopoverOpen,
    ],
  )

  return (
    <Dialog>
      <DialogTrigger asChild>
        <PopoverMenuItem id="duplicate-entry-button">
          <PopoverMenuItemIcon>
            <CopyOutlineIcon />
          </PopoverMenuItemIcon>
          {polyglot.t(`${polyglotPrefix}duplicate_cta`)}
        </PopoverMenuItem>
      </DialogTrigger>
      <PopoverMenuItemSeparator />
      <DialogContent>
        <DialogHeading>
          {polyglot.t(`${polyglotPrefix}duplicate_dialog_header`)}
          <DialogCloseX id="close-dialog-button"/>
        </DialogHeading>
        <DialogButtons>
          <DialogButton stylePreset="secondary">
            {polyglot.t(`${polyglotPrefix}duplicate_dialog_cancel`)}
          </DialogButton>
          <DialogButton
            id="confirm-duplicate"
            onClick={hasUnsavedChanges ? openDiscardChangesDialog : duplicateEntryCallback}
          >
            {polyglot.t(`${polyglotPrefix}duplicate_dialog_confirm`)}
          </DialogButton>
        </DialogButtons>
      </DialogContent>
      <DiscardChangesDialog
        open={discardChangesOpen}
        setOpen={setDiscardChangesOpen}
        discardChanges={duplicateEntryCallback}
      />
    </Dialog>
  )
}
