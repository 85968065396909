import {
  DialogCloseX,
  DialogContent,
  DialogHeading,
  DialogBody,
  DialogButton,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ReactNode } from "react"
import {
  useState,
  useEffect,
} from "react"

import styles from "./DataSource.module.scss"
import { usePolyglot } from "src/contexts"
import { SourceType } from "src/types"
import type { Source } from "src/types"

interface Props {
  children?: ReactNode;
  source?: Source;
}

const polyglotPrefix = "pages.settings.data_source.no_new_readings_dialog."

export function NoNewReadingsDialogContent(props: Props) {
  const { source } = props

  const polyglot = usePolyglot()

  const [
    sourceType,
    setSourceType,
  ] = useState<SourceType | null>(source?.source_type ?? null)

  useEffect(
    () => {
      if (source?.source_type) {
        setSourceType(source.source_type)
      }
    },
    [
      source?.source_type,
      setSourceType,
    ],
  )

  return (
    <DialogContent>
      {props.children}
      <DialogHeading>
        {polyglot.t(`${polyglotPrefix}heading`)}
        <DialogCloseX id="close-dialog-button" />
      </DialogHeading>
      <DialogBody className={styles.dialogBody}>
        <div className={styles.noNewReadingsMessage}>
          {polyglot.t(`${polyglotPrefix}description`)}
        </div>
        {sourceType === SourceType.Nightscout ? (
          <div>
            {polyglot.t(`${polyglotPrefix}nightscout_troubleshooting`)}
          </div>
        ) : (
          <div>
            {polyglot.t(`${polyglotPrefix}dexcom_troubleshooting.message`)}
            <ul className={styles.dexcomTroubleshootingList}>
              <li>
                {polyglot.t(`${polyglotPrefix}dexcom_troubleshooting.bullet_one`)}
              </li>
              <li>
                {polyglot.t(`${polyglotPrefix}dexcom_troubleshooting.bullet_two`)}
              </li>
            </ul>
          </div>
        )}
        <div>
          {polyglot.t(`${polyglotPrefix}fallback_message`)}
        </div>
        <DialogButton stylePreset="primary"
          id="confirm-add-button"
        >
          {polyglot.t(`${polyglotPrefix}confirm_cta`)}
        </DialogButton>
      </DialogBody>
    </DialogContent>
  )
}
