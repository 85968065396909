import { DisconnectOutlineIcon } from "@tandemdiabetes/sugarmate-react-components"
import styles from "./UserHeader.module.scss"
import { usePolyglot } from "src/contexts"

interface Props {
  userName: string;
}

export function NoDataSourceError(props: Props) {
  const { userName } = props
  const polyglot = usePolyglot()

  return (
    <>
      <div className={styles.errorContainer}>
        <DisconnectOutlineIcon className={styles.errorIcon} />
        <div className={styles.errorText}
          id="no-data-source-message"
        >
          {polyglot.t("pages.home.user_header.no_data_source_message")}
        </div>
      </div>
      <div className={styles.info}
        id="users-data"
      >
        {polyglot.t(
          "pages.home.user_header.users_data",
          { user: userName },
        )}
      </div>
    </>
  )
}
