import {
  DashFillIcon,
  IconButton,
  useToasterContext,
  SortableGridItem,
  useSortableGridContext,
} from "@tandemdiabetes/sugarmate-react-components"
import classNames from "classnames"
import { useCallback } from "react"
import type { MouseEventHandler } from "react"
import { useEditInsightModalContext } from "../EditInsightModal"
import { useInsightsContext } from "../InsightsContext"
import styles from "./Tile.module.scss"
import { TileChildren } from "./TileChildren"
import { useRemoveInsight } from "src/hooks"
import { RemoveInsightErrorToast } from "src/toasts"
import type {
  InsightKey,
  InsightRange,
} from "src/types"
import { injectProps } from "src/utils"


interface Props {
  id: string;
  insightKey: InsightKey;
  range?: InsightRange;
  units?: number;
}

export function SortableTile(props: Props) {
  const {
    insightKey,
    range,
  } = props

  const removeInsight = useRemoveInsight()
  const { openEditInsightModal } = useEditInsightModalContext()
  const { showToast } = useToasterContext()
  const { isEditing } = useInsightsContext()
  const { activeId } = useSortableGridContext()

  const deleteInsight: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      void (async () => {
        const ToastComponent = injectProps(
          RemoveInsightErrorToast,
          { insightKey },
        )

        try {
          await removeInsight({
            key: insightKey,
            range,
          })
        } catch (e) {
          showToast(
            "remove-insight-error-toast",
            ToastComponent,
          )
        }
      })()
    },
    [
      insightKey,
      range,
      removeInsight,
      showToast,
    ],
  )

  const onClick = useCallback(
    () => {
      openEditInsightModal({
        key: insightKey,
        range,
      })
    },
    [
      openEditInsightModal,
      insightKey,
      range,
    ],
  )

  const iconButtonClassName = classNames(
    styles.delete,
    { [styles.deleteHidden]: !isEditing || !!activeId },
  )

  return (
    <div className={styles.container}>
      <IconButton
        onClick={deleteInsight}
        stylePreset="withoutBackground"
        className={iconButtonClassName}
        disabled={!isEditing}
        id={`remove-button-${insightKey}`}
      >
        <DashFillIcon />
      </IconButton>
      <SortableGridItem
        id={insightKey}
        className={styles.tile}
        onClick={onClick}
      >
        <TileChildren {...props} />
      </SortableGridItem>
    </div>
  )
}
