import {
  Slider,
  SliderInput,
  SliderTracks,
  SliderValue,
  SliderValueInput,
  SliderValueLabel,
  SliderValueLabelContainer,
} from "@tandemdiabetes/sugarmate-react-components"
import { useCallback } from "react"
import type { ChangeEventHandler } from "react"

import { defaultInsulinActivity } from "./defaultInsulinActivity"
import { usePolyglot } from "src/contexts"
import styles from "src/styles/entryModal.module.scss"
import type { InsulinActivity } from "src/types"
import { insulinPrecisionToUnits } from "src/utils"

interface Props {
  activity: InsulinActivity;
  updateActivity: (a: InsulinActivity) => void;
}

const polyglotPrefix = "components.entry_modal.insulin.units."
const id = "insulin-units-range-input"
const max = 99
const min = 0

/*
 * renders a number label and range input for choosing number of units
 */
export function InsulinFormUnitsRangeInput(props: Props) {
  const {
    activity,
    updateActivity,
  } = props

  const polyglot = usePolyglot()

  // save changes to num_units
  const onNumUnitsChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const numUnits = Number(e.target.value)

      updateActivity({
        ...activity,
        num_units: numUnits,
      })
    },
    [
      activity,
      updateActivity,
    ],
  )

  const step = insulinPrecisionToUnits(activity.insulin_med.precision)

  const unset = activity.num_units === 0 &&
    activity.insulin_med.account_insulin_id === defaultInsulinActivity.insulin_med.account_insulin_id

  return (
    <div className={styles.row}>
      <SliderValue sizePreset="large">
        <SliderValueInput
          max={max}
          min={min}
          step={step}
          onChange={onNumUnitsChange}
          id={id}
          value={unset ? "" : activity.num_units}
        />
        <SliderValueLabelContainer>
          <SliderValueLabel htmlFor={id}>
            {polyglot.t(
              `${polyglotPrefix}label`,
              activity.num_units,
            )}
          </SliderValueLabel>
        </SliderValueLabelContainer>
      </SliderValue>
      <Slider className={styles.rangeInputSlider}>
        <SliderInput
          min={min}
          max={max}
          step={step}
          id={id}
          value={activity.num_units}
          onChange={onNumUnitsChange}
        />
        <SliderTracks
          min={min}
          max={max}
        />
      </Slider>
    </div>
  )
}
