import { useCalculationsContext } from "../CalculationsContext"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileUnit } from "./TileUnit"
import { TileValue } from "./TileValue"
import { usePolyglot } from "src/contexts"
import { InsightKey } from "src/types"
import { round } from "src/utils"

interface Props {
  range: number;
}

export function FoodTotalProtein(props: Props) {
  const { range } = props

  const polyglot = usePolyglot()

  const { foodCalculations } = useCalculationsContext()
  const { totalProtein } = foodCalculations[range] || {}
  const value = totalProtein === undefined ?
    NaN :
    round(
      totalProtein,
      1,
    )

  const unit = polyglot.t(
    "unit.grams",
    isNaN(value) ? 0 : value,
  )

  return (
    <>
      <TileTitle insightKey={InsightKey.FoodTotalProtein} />
      <TileTimeSpan
        id={`${InsightKey.FoodTotalProtein}-time-range`}
        range={range}
      />
      <TileValue id={`${InsightKey.FoodTotalProtein}-value`}>
        {isNaN(value) ? "--" : value.toLocaleString(navigator.language)}
      </TileValue>
      <TileUnit id={`${InsightKey.FoodTotalProtein}-unit`}>
        {unit}
      </TileUnit>
    </>
  )
}
