import dayjs from "dayjs"
import {
  useMemo,
  useRef,
} from "react"
import { useCalculationsContext } from "../CalculationsContext"
import styles from "./MiniGraph.module.scss"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import {
  useEventsAndSubEntriesOfType,
  useDeviceSettingsProperty,
} from "src/hooks"
import type { GlucoseActivity } from "src/types"
import {
  ActivityType,
  InsightKey,
  InsightRange,
} from "src/types"
import { createStartedAtRangeSelector } from "src/utils"

interface Props {
  range: InsightRange;
}

export function MiniGraph(props: Props) {
  const { range } = props

  const chartTop = useDeviceSettingsProperty("chart_top")
  const chartBottom = useDeviceSettingsProperty("chart_bottom")

  const {
    queryEndTime,
    timeSinceWakeUpInHours,
  } = useCalculationsContext()

  const rangeInHours = range === InsightRange.sinceWakeUp
    ? (timeSinceWakeUpInHours ?? 0)
    : range

  const selector = useMemo(
    () => createStartedAtRangeSelector(
      queryEndTime,
      (rangeInHours) + 0.25,
    ),
    [
      queryEndTime,
      rangeInHours,
    ],
  )

  const {
    events,
    subEntries,
    isFetchingEvents,
    isFetchingEntries,
  } = useEventsAndSubEntriesOfType(
    ActivityType.Glucose,
    selector,
  )

  const currentPath = useRef<string>("")

  // drawing the line from right to left
  const path = useMemo(
    () => {
      if (isFetchingEvents || isFetchingEntries) {
        return currentPath.current
      }

      let pathString = ""
      let lastPoint: [number, number] | undefined
      let minValue = Number.POSITIVE_INFINITY
      let maxValue = Number.NEGATIVE_INFINITY

      const graphEnd = dayjs(queryEndTime)
      function addPoint(
        time: string,
        g: GlucoseActivity,
      ) {
        const diff = -graphEnd.diff(
          time,
          "minute",
          true,
        )
        const point: [number, number] = [
          diff,
          g.mg_dl,
        ]

        lastPoint = point
        pathString += ` ${point[0]},${point[1]} `
        maxValue = Math.max(
          g.mg_dl,
          maxValue,
        )
        minValue = Math.min(
          g.mg_dl,
          minValue,
        )
      }

      let eventIndex = 0
      let subEntryIndex = 0

      while (eventIndex + subEntryIndex <= (events.length - 1) + (subEntries.length - 1)) {
        if (eventIndex + subEntryIndex === 0) {
          pathString += "M"
        } else if ((eventIndex + subEntryIndex) % 2 === 1) {
          pathString += "S"
        }

        const glucoseEvent = events[eventIndex]
        const subEntry = subEntries[subEntryIndex]

        if (glucoseEvent && subEntry) {
          if (dayjs(subEntry.started_at).isAfter(glucoseEvent.started_at)) {
            addPoint(
              subEntry.started_at,
              subEntry.glucose,
            )
            subEntryIndex++
          } else {
            addPoint(
              glucoseEvent.started_at,
              glucoseEvent.glucose,
            )
            eventIndex++
          }
        } else if (subEntry) {
          addPoint(
            subEntry.started_at,
            subEntry.glucose,
          )
          subEntryIndex++
        } else if (glucoseEvent) {
          addPoint(
            glucoseEvent.started_at,
            glucoseEvent.glucose,
          )
          eventIndex++
        }
      }

      if ((eventIndex + subEntryIndex) % 2 === 0 && lastPoint) {
        pathString += ` ${lastPoint[0]},${lastPoint[1]} `
      }

      currentPath.current = pathString
      return pathString
    },
    [
      currentPath,
      events,
      subEntries,
      isFetchingEvents,
      isFetchingEntries,
      queryEndTime,
    ],
  )

  const graphStartTime = dayjs(queryEndTime).subtract(
    rangeInHours,
    "hour",
  )
  const graphStart = dayjs(queryEndTime).diff(
    graphStartTime,
    "minute",
    true,
  )
  const viewBox = `${-1 * graphStart} ${chartBottom} ${graphStart} ${chartTop - chartBottom}`

  return (
    <>
      <TileTitle insightKey={InsightKey.MiniGraph} />
      <TileTimeSpan id={`${InsightKey.MiniGraph}-time-range`}
        range={range}
      />
      <div className={styles.container}>
        <svg
          className={styles.graph}
          id={`${InsightKey.MiniGraph}-mini-graph`}
          viewBox={viewBox}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={path}
            vectorEffect="non-scaling-stroke"
          />
        </svg>
      </div>
    </>
  )
}
