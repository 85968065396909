import {
  FieldSet,
  SelectButton,
  SelectButtons,
  Input,
  Label,
  Legend,
  InfoButton,
} from "@tandemdiabetes/sugarmate-react-components"
import dayjs from "dayjs"
import type { ChangeEventHandler } from "react"
import {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react"
import styles from "./Reports.module.scss"
import { usePolyglot } from "src/contexts"
import { useToday } from "src/hooks"
import type { Report } from "src/types"

const polyglotPrefix = "pages.generate_report."

enum ReportDateRange {
  OneMonth = "one_month",
  ThreeMonths = "three_months",
  Custom = "custom",
}

const dateInputFormat = "YYYY-MM-DD"

interface Props {
  report: Report;
  updateReport: (report: Partial<Report>) => void;
}

export function DateRangeInput(props: Props) {
  const {
    updateReport,
    report,
  } = props

  const polyglot = usePolyglot()
  const today = useToday()

  const [
    currentDateRange,
    setCurrentDateRange,
  ] = useState<ReportDateRange>(ReportDateRange.OneMonth)

  useEffect(
    () => {
      if (currentDateRange === ReportDateRange.Custom) {
        return
      }

      const endDate = dayjs(today).endOf("day")
      const startDate = dayjs(today).subtract(
        currentDateRange === ReportDateRange.OneMonth ? 1 : 3,
        "month",
      )
        .startOf("day")

      updateReport({
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
      })
    },
    [
      today,
      currentDateRange,
      updateReport,
    ],
  )

  const onDateRangeChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const newDateRange = e.target.value as ReportDateRange
      setCurrentDateRange(newDateRange)
    },
    [setCurrentDateRange],
  )

  const onStartDateChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const newStartDate = dayjs(
        e.target.value,
        dateInputFormat,
      ).startOf("day")
      updateReport({
        start_date: newStartDate.toISOString(),
      })
    },
    [updateReport],
  )

  const onEndDateChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const newEndDate = dayjs(
        e.target.value,
        dateInputFormat,
      ).endOf("day")
      updateReport({
        end_date: newEndDate.toISOString(),
      })
    },
    [updateReport],
  )

  const {
    maxDate,
    minDate,
  } = useMemo(
    () => {
      const max = dayjs(today).format(dateInputFormat)
      const min = dayjs(today)
        .subtract(
          6,
          "month",
        )
        .format(dateInputFormat)

      return {
        maxDate: max,
        minDate: min,
      }
    },
    [today],
  )

  return (
    <>
      <FieldSet>
        <Legend id="date-range">
          {polyglot.t(`${polyglotPrefix}date_range.legend`)}
          <InfoButton
            title={polyglot.t(`${polyglotPrefix}date_range.info_title`)}
          >
            {polyglot.t(`${polyglotPrefix}date_range.info_description`)}
          </InfoButton>
        </Legend>
        <SelectButtons className={styles.inputContainer}>
          <SelectButton
            id="date-range-option-one-month"
            htmlFor="date-range-one-month"
            name="date-range"
            value={ReportDateRange.OneMonth}
            onChange={onDateRangeChange}
            checked={currentDateRange === ReportDateRange.OneMonth}
            required
          >
            {polyglot.t(
              "time.month_count",
              1,
            )}
          </SelectButton>
          <SelectButton
            id="date-range-option-three-months"
            htmlFor="date-range-three-months"
            name="date-range"
            value={ReportDateRange.ThreeMonths}
            onChange={onDateRangeChange}
            checked={currentDateRange === ReportDateRange.ThreeMonths}
            required
          >
            {polyglot.t(
              "time.month_count",
              3,
            )}
          </SelectButton>
          <SelectButton
            id="date-range-option-custom"
            htmlFor="date-range-custom"
            name="date-range"
            value={ReportDateRange.Custom}
            onChange={onDateRangeChange}
            checked={currentDateRange === ReportDateRange.Custom}
            required
          >
            {polyglot.t(`${polyglotPrefix}date_range.custom_cta`)}
          </SelectButton>
        </SelectButtons>
      </FieldSet>
      {currentDateRange === ReportDateRange.Custom && (
        <>
          <div>
            <Label>
              {polyglot.t(`${polyglotPrefix}custom_date_range.label`)}
              <InfoButton
                title={polyglot.t(
                  `${polyglotPrefix}custom_date_range.info_title`,
                )}
              >
                {polyglot.t(
                  `${polyglotPrefix}custom_date_range.info_description`,
                )}
              </InfoButton>
            </Label>
            <div className={styles.inputContainer}>
              <Input
                className={styles.customDateInput}
                type="date"
                id="start-date"
                min={minDate}
                max={maxDate}
                value={dayjs(report.start_date).format(dateInputFormat)}
                onChange={onStartDateChange}
                required
              />
              <div className={styles.customDateInputSeparator}>
                –
              </div>
              <Input
                className={styles.customDateInput}
                type="date"
                id="end-date"
                min={minDate}
                max={maxDate}
                value={dayjs(report.end_date).format(dateInputFormat)}
                onChange={onEndDateChange}
                required
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
