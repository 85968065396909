import dayjs from "dayjs"
import { useMemo } from "react"
import { useCalculationsContext } from "../CalculationsContext"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileUnit } from "./TileUnit"
import { TileValue } from "./TileValue"
import { usePolyglot } from "src/contexts"
import { useThisMinute } from "src/hooks"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"
import { round } from "src/utils"

interface Props {
  range: InsightRange;
}

export function TimeInRange(props: Props) {
  const { range } = props

  const thisMinute = useThisMinute()
  const polyglot = usePolyglot()
  const { timeInRangeCalculation } = useCalculationsContext()

  const isNullish = timeInRangeCalculation === undefined || timeInRangeCalculation === null

  const value = useMemo(
    () => isNullish
      ? 0
      : dayjs(thisMinute).diff(
        timeInRangeCalculation,
        "minute",
        true,
      ),
    [
      timeInRangeCalculation,
      thisMinute,
    ],
  )

  const unit = value >= 59.5
    ? polyglot.t(
      "time.hours",
      round(
        value / 60,
        1,
      ),
    )
    : polyglot.t(
      "time.minute_unit",
      round(value),
    )

  const valueString = isNullish ? "--" :
    value >= 59.5
      ? round(
        value / 60,
        1,
      )
      : round(value)

  return (
    <>
      <TileTitle insightKey={InsightKey.TimeInRange} />
      <TileTimeSpan id={`${InsightKey.TimeInRange}-time-range`}
        range={range}
      />
      <TileValue id={`${InsightKey.TimeInRange}-value`}>
        {valueString}
      </TileValue>
      <TileUnit id={`${InsightKey.TimeInRange}-unit`}>
        {unit}
      </TileUnit>
    </>
  )
}
