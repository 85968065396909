import {
  EllipsisIcon,
  Popover,
  PopoverMenu,
  PopoverTrigger,
} from "@tandemdiabetes/sugarmate-react-components"
import type {
  Ref,
  ComponentPropsWithoutRef,
} from "react"
import {
  forwardRef,
  useMemo,
} from "react"

import styles from "./ContextMenu.module.scss"
import { DeleteEntry } from "./DeleteEntry"
import { DeleteSubEntry } from "./DeleteSubEntry"
import { DuplicateEntry } from "./DuplicateEntry"
import { DuplicateSubEntry } from "./DuplicateSubEntry"
import { FavoriteEntry } from "./FavoriteEntry"

import type {
  EditableSubEntry,
  EditableUserEntry,
} from "src/types"

interface Props extends ComponentPropsWithoutRef<"button"> {
  currentSubEntry?: EditableSubEntry;
  currentUserEntry: EditableUserEntry;
}

// rename to entry modal context menu
export const ContextMenu = forwardRef<HTMLButtonElement, Props>(
  function ContextMenu(props: Props, propRef: Ref<HTMLButtonElement>) {
    const {
      currentSubEntry,
      currentUserEntry,
      ...buttonProps
    } = props

    const numberOfSavedSubentries = useMemo(
      () => currentUserEntry.subentries.filter(
        (subEntry) => !!subEntry._id,
      ).length,
      [currentUserEntry.subentries],
    )

    // if the user is creating a new user entry don't render anything
    if (!currentUserEntry.id) {
      return null
    }

    // if the user is creating a new sub entry don't render anything
    if (currentSubEntry && !currentSubEntry._id) {
      return null
    }

    const subEntryContextMenu = currentSubEntry && numberOfSavedSubentries > 1

    // if the user is editing a sub entry render the sub entry popover
    if (subEntryContextMenu) {
      return (
        <Popover
          placement="bottom-end"
          hideTrigger
        >
          <PopoverTrigger
            {...buttonProps}
            ref={propRef}
            className={styles.trigger}
          >
            <EllipsisIcon className={styles.triggerIcon} />
          </PopoverTrigger>
          <PopoverMenu aria-label="context menu">
            <FavoriteEntry />
            <DuplicateSubEntry />
            <DeleteSubEntry />
          </PopoverMenu>
        </Popover>
      )
    }

    // if the user is editing an entry render or a sub entry for an entry
    // that has only one subentry render the user entry popover
    return (
      <>
        <Popover
          placement="bottom-end"
          hideTrigger
        >
          <PopoverTrigger
            {...buttonProps}
            ref={propRef}
            className={styles.trigger}
            id="pop-over-menu"
          >
            <EllipsisIcon className={styles.triggerIcon} />
          </PopoverTrigger>
          <PopoverMenu aria-label="context menu">
            <FavoriteEntry />
            <DuplicateEntry />
            <DeleteEntry />
          </PopoverMenu>
        </Popover>
      </>
    )
  },
)
