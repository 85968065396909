import {
  Dialog,
  DialogBody,
  DialogButton,
  DialogButtons,
  DialogCloseX,
  DialogContent,
  DialogHeading,
} from "@tandemdiabetes/sugarmate-react-components"
import type {
  MouseEventHandler,
  ReactNode,
} from "react"
import styles from "./RemoveDialog.module.scss"
import { usePolyglot } from "src/contexts"

interface Props {
  open: boolean;
  setOpen: (o: boolean) => void;
  remove: MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode; // children are rendered in the dialog body
  title: string;
  removeCta?: string;
  cancelCta?: string;
}

const polyglotPrefix = "components.remove_dialog."

/*
 * renders a dialog asking the user to confirm that they want to remove something
 */
export function RemoveDialog(props: Props) {
  const {
    children,
    setOpen,
    open: isOpen,
    remove,
    title,
    cancelCta,
    removeCta,
  } = props

  const polyglot = usePolyglot()

  return (
    <Dialog
      open={isOpen}
      onOpenChange={setOpen}
    >
      <DialogContent>
        <DialogHeading>
          {title}
          <DialogCloseX />
        </DialogHeading>
        {children && (
          <DialogBody className={styles.dialogBody}>
            {children}
          </DialogBody>
        )}
        <DialogButtons>
          <DialogButton
            stylePreset="destructive"
            onClick={remove}
            id="confirm-delete"
          >
            {removeCta || polyglot.t(`${polyglotPrefix}remove_cta`)}
          </DialogButton>
          <DialogButton>
            {cancelCta || polyglot.t(`${polyglotPrefix}cancel_cta`)}
          </DialogButton>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}
