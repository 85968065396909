import {
  IconButton,
  PlusIcon,
  Label,
  InfoButton,
  MenuButton,
} from "@tandemdiabetes/sugarmate-react-components"
import { useCallback } from "react"
import { AccountMedicationComponent } from "./AccountMedicationComponent"
import styles from "./InsulinAndMedication.module.scss"
import { useAccountMedicationModalContext } from "src/components"
import { usePolyglot } from "src/contexts"
import { useAccountMedications } from "src/hooks"

const polyglotPrefix = "pages.settings.insulin_and_medication.medication."

export function AccountMedications() {
  const polyglot = usePolyglot()
  const { openAccountMedicationModal } = useAccountMedicationModalContext()

  const {
    result: accountMedications,
    isFetching,
  } = useAccountMedications()

  const onClick = useCallback(
    () => {
      openAccountMedicationModal()
    },
    [openAccountMedicationModal],
  )

  return (
    <div>
      <div className={styles.labelRow}>
        <Label id="medication-label">
          {polyglot.t(`${polyglotPrefix}label`)}
          <InfoButton title={polyglot.t(`${polyglotPrefix}title`)}>
            {polyglot.t(`${polyglotPrefix}description`)}
          </InfoButton>
        </Label>
        <IconButton
          onClick={onClick}
          stylePreset="withoutBackground"
          id="medication-add-icon"
        >
          <PlusIcon />
        </IconButton>
      </div>
      {isFetching ? (
        <MenuButton
          disabled
          className={styles.loadingButton}
          onClick={onClick}
        >
          {polyglot.t(`${polyglotPrefix}empty_cta`)}
        </MenuButton>
      ) : accountMedications.length == 0 ? (
        <MenuButton
          className={styles.emptyButton}
          onClick={onClick}
        >
          {polyglot.t(`${polyglotPrefix}empty_cta`)}
        </MenuButton>
      ) : (
        <ul className={styles.list}>
          {
            accountMedications.map(
              (accountMedication) => (
                <AccountMedicationComponent
                  key={accountMedication.id}
                  accountMedication={accountMedication}
                />
              ),
            )
          }
        </ul>
      )}
    </div>
  )
}
