import { useCalculationsContext } from "../CalculationsContext"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileUnit } from "./TileUnit"
import { TileValue } from "./TileValue"
import { usePolyglot } from "src/contexts"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"
import { round } from "src/utils"

interface Props {
  range: InsightRange;
}

export function ExerciseTotalDuration(props: Props) {
  const { range } = props

  const polyglot = usePolyglot()

  const { exerciseCalculations } = useCalculationsContext()
  const { totalInSeconds } = exerciseCalculations[range] || {}

  const value = totalInSeconds === undefined ?
    NaN :
    round(totalInSeconds / 60)

  const unit = polyglot.t(
    "time.min_unit",
    isNaN(value) ? 0 : value,
  )

  return (
    <>
      <TileTitle insightKey={InsightKey.ExerciseTotalDuration} />
      <TileTimeSpan
        range={range}
        id={`${InsightKey.ExerciseTotalDuration}-time-range`}
      />
      <TileValue id={`${InsightKey.ExerciseTotalDuration}-value`}>
        {isNaN(value) ? "--" : `${value}`}
      </TileValue>
      <TileUnit id={`${InsightKey.ExerciseTotalDuration}-unit`}>
        {unit}
      </TileUnit>
    </>
  )
}
