import {
  ModalBody,
  ModalCloseX,
  ModalHeading,
} from "@tandemdiabetes/sugarmate-react-components"
import { useEffect } from "react"

import { ContextMenu } from "../ContextMenu"
import { DateTimeInput } from "../DateTimeInput"
import { ImageThumbnail } from "../ImageThumbnail"
import { NoteInput } from "../NoteInput"
import { SubEntryFormButtons } from "../SubEntryFormButtons"
import { UploadImage } from "../UploadImage"

import { AccountMedicationButton } from "./AccountMedicationButton"
import { defaultMedicationActivity } from "./defaultMedicationActivity"
import { MedicationFormNameSelect } from "./MedicationFormNameSelect"
import { MedicationFormUnitsRangeInput } from "./MedicationFormUnitsRangeInput"
import { useOnAccountMedicationCreated } from "./useOnAccountMedicationCreated"
import { useOnCurrentAccountMedicationDeleted } from "./useOnCurrentAccountMedicationDeleted"
import { useOnCurrentAccountMedicationUpdated } from "./useOnCurrentAccountMedicationUpdated"
import { AccountMedicationModal } from "src/components/AccountMedicationModal"
import {
  ActivityIcon,
  ActivityName,
} from "src/components/Activity"
import { useAccountMedication } from "src/hooks"
import styles from "src/styles/entryModal.module.scss"
import type {
  MedicationActivity,
  EditableSubEntry,
  EditableUserEntry,
} from "src/types"
import { ActivityType } from "src/types"

interface Props {
  updateActivity: (a: MedicationActivity) => void;
  currentSubEntry: EditableSubEntry<ActivityType.Medication>;
  updateSubEntry: (s: Partial<EditableSubEntry<ActivityType.Medication>>) => void;
  currentUserEntry: EditableUserEntry;
  setCurrentUserEntry: (userEntry: EditableUserEntry) => void;
}

/*
 * renders the form for editing a medication subentry
 */
export function MedicationForm(props: Props) {
  const {
    updateActivity,
    currentSubEntry,
    updateSubEntry,
    currentUserEntry,
    setCurrentUserEntry,
  } = props

  const {
    med: activity = defaultMedicationActivity,
  } = currentSubEntry

  // initialize the activity to the default if it isn't defined
  useEffect(
    () => {
      if (!currentSubEntry.med) {
        updateActivity(defaultMedicationActivity)
      }
    },
    [
      currentSubEntry.med,
      updateActivity,
    ],
  )

  // the account medication currently referenced in the medication activity
  const currentAccountMedication = useAccountMedication(
    activity.medication.account_med_id,
  )

  // if the current account medication is updated, upate the activity too
  useOnCurrentAccountMedicationUpdated(
    activity,
    updateActivity,
    currentAccountMedication,
  )

  // if a new account medication is created then use it for the activity
  useOnAccountMedicationCreated(
    activity,
    updateActivity,
  )

  // reset the activity if it is a new activity and its account medication is removed
  useOnCurrentAccountMedicationDeleted(
    activity,
    updateActivity,
    currentAccountMedication,
    currentSubEntry,
  )

  return (
    <>
      <ModalHeading>
        <div className={styles.headingContent}>
          <ActivityIcon
            activityType={ActivityType.Medication}
            className={styles.activityIcon}
          />
          <ActivityName activityType={ActivityType.Medication} />
          <DateTimeInput
            currentUserEntry={currentUserEntry}
            setCurrentUserEntry={setCurrentUserEntry}
          />
        </div>
        <ContextMenu
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
        />
        <ModalCloseX id="medication-entry-close-btn"/>
      </ModalHeading>
      <ModalBody className={styles.modalBody}>
        <MedicationFormUnitsRangeInput
          activity={activity}
          updateActivity={updateActivity}
          units={activity.medication.units || currentAccountMedication?.units}
        />
        <div className={styles.row}>
          <MedicationFormNameSelect
            activity={activity}
            updateActivity={updateActivity}
          />
          <AccountMedicationModal>
            <AccountMedicationButton
              currentAccountMedication={currentAccountMedication}
            />
          </AccountMedicationModal>
        </div>
        <div className={styles.row}>
          <NoteInput
            currentSubEntry={currentSubEntry}
            updateSubEntry={updateSubEntry}
          />
          <UploadImage updateSubEntry={updateSubEntry} />
        </div>
        <ImageThumbnail
          currentSubEntry={currentSubEntry}
          updateSubEntry={updateSubEntry}
        />
        <SubEntryFormButtons
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
        />
      </ModalBody>
    </>
  )
}
