import {
  Dialog,
  DialogCloseX,
  DialogButton,
  DialogButtons,
  DialogContent,
  DialogHeading,
  DialogTrigger,
  PopoverMenuItem,
  PopoverMenuItemIcon,
  TrashCanOutlineIcon,
  usePopoverContext,
  useToasterContext,
} from "@tandemdiabetes/sugarmate-react-components"
import { useCallback } from "react"
import { useEntryModalPrivateContext } from "../EntryModalPrivateContext"
import { useEntryModalPublicContext } from "../EntryModalPublicContext"
import styles from "./ContextMenu.module.scss"
import { usePolyglot } from "src/contexts"
import {
  DeleteEntryErrorToast,
  DeleteEntrySuccessToast,
} from "src/toasts"

const polyglotPrefix = "components.entry_modal.entry_context_menu."

export function DeleteEntry() {
  const polyglot = usePolyglot()
  const { destroyEntry } = useEntryModalPrivateContext()
  const { closeEntryModal } = useEntryModalPublicContext()
  const { setOpen: setPopoverOpen } = usePopoverContext()
  const { showToast } = useToasterContext()

  const deleteAndClose = useCallback(
    () => {
      void (async () => {
        try {
          await destroyEntry()

          showToast(
            "delete-entry-success-toast",
            DeleteEntrySuccessToast,
          )
        } catch (e) {

          showToast(
            "delete-entry-error-toast",
            DeleteEntryErrorToast,
          )
        }

        // TODO move this ?
        setPopoverOpen(false)
      })()
    },
    [
      destroyEntry,
      closeEntryModal,
      setPopoverOpen,
    ],
  )

  return (
    <Dialog>
      <DialogTrigger asChild>
        <PopoverMenuItem className={styles.deleteButton}
          id="delete-button"
        >
          <PopoverMenuItemIcon >
            <TrashCanOutlineIcon />
          </PopoverMenuItemIcon>
          {polyglot.t(`${polyglotPrefix}delete_cta`)}
        </PopoverMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeading>
          {polyglot.t(`${polyglotPrefix}delete_dialog_header`)}
          <DialogCloseX />
        </DialogHeading>
        <DialogButtons>
          <DialogButton
            stylePreset="destructive"
            onClick={deleteAndClose}
            id="confirm-delete"
          >
            {polyglot.t(`${polyglotPrefix}delete_dialog_confirm`)}
          </DialogButton>
          <DialogButton>
            {polyglot.t(`${polyglotPrefix}delete_dialog_cancel`)}
          </DialogButton>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}
