import {
  InfoButton,
  Label,
  Slider,
  SliderInput,
  SliderTracks,
  SliderValue,
  SliderValueInput,
  SliderValueLabel,
  SliderValueLabelContainer,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEvent } from "react"
import { useCallback } from "react"

import styles from "./Ranges.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useInputPropsForValue,
  useGetGlucoseValue,
  useSetGlucoseValue,
  useGlucoseInputProps,
  useReadableGlucoseUnit,
  useDeviceSettingsProperty,
  useFormatNumberInputGlucoseValue,
} from "src/hooks"
import type {
  Settings,
  UserDeviceSettings,
} from "src/types"
import { roundForStep } from "src/utils"

const polyglotPrefix = "pages.settings.ranges.urgent_low."

const inputProps = {
  min: 40,
  max: 100,
  step: 1,
}
const numberId = "urgent-low-number"

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

export function UrgentLow(props: Props) {
  const {
    updatedAt,
    updateSettings,
  } = props

  const polyglot = usePolyglot()
  const readableGlucoseUnit = useReadableGlucoseUnit()
  const formatNumberInputGlucoseValue = useFormatNumberInputGlucoseValue()
  const getGlucoseValue = useGetGlucoseValue()
  const setGlucoseValue = useSetGlucoseValue()
  const currentUrgentLow = useDeviceSettingsProperty("urgent_low")

  const adapter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setGlucoseValue(Number(e.target.value)),
    [setGlucoseValue],
  )

  const onValueChange = useCallback(
    (urgentLow: UserDeviceSettings["urgent_low"]) => {
      updateSettings({
        urgent_low: urgentLow,
      })
    },
    [updateSettings],
  )

  const {
    value,
    onChange,
  } = useInputPropsForValue({
    value: currentUrgentLow,
    onChange: onValueChange,
    adapter,
    updatedAt,
  })

  const sharedProps = useGlucoseInputProps(inputProps)

  const roundValue = roundForStep(
    getGlucoseValue(value),
    sharedProps.step,
  )

  return (
    <div>
      <Label>
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}title`)}>
          <div>
            {polyglot.t(`${polyglotPrefix}description_one`)}
          </div>
          <div>
            {polyglot.t(`${polyglotPrefix}description_two`)}
          </div>
        </InfoButton>
      </Label>
      <div className={styles.inputContainer}>
        <SliderValue
          sizePreset="small"
          alignmentPreset="center"
        >
          <SliderValueInput
            {...sharedProps}
            value={formatNumberInputGlucoseValue(roundValue)}
            onChange={onChange}
            id={numberId}
          />
          <SliderValueLabelContainer>
            <SliderValueLabel htmlFor={numberId}>
              {readableGlucoseUnit}
            </SliderValueLabel>
          </SliderValueLabelContainer>
        </SliderValue>
        <Slider className={styles.rangeInputSlider}
          id="urgent-low-slider-container"
        >
          <SliderInput
            {...sharedProps}
            value={roundValue}
            onChange={onChange}
            id="urgent-low-slider"
          />
          <SliderTracks
            min={sharedProps.min}
            max={sharedProps.max}
          />
        </Slider>
      </div>
    </div>
  )
}
