import {
  Dropdown,
  DropdownCaret,
  DropdownCurrentSelection,
  DropdownOption,
  DropdownSelect,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"

import { defaultInsulinActivity } from "./defaultInsulinActivity"
import styles from "./InsulinFormNameSelect.module.scss"
import { useNameSelectOptions } from "./useNameSelectOptions"
import type { InsulinActivity } from "src/types"

interface Props {
  activity: InsulinActivity;
  updateActivity: (a: InsulinActivity) => void;
}

/*
 * renders a select element for choosing insulin name
 */
export function InsulinFormNameSelect(props: Props) {
  const {
    activity,
    updateActivity,
  } = props

  // calculate the options for the select menu
  const selectOptions = useNameSelectOptions(activity)

  const id = "insulin-name-dropdown"


  // save changes to account_insulin_id
  const onInsulinChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      // TODO this shouldn't be a number in the future
      const accountInsulinId = Number(e.target.value)

      updateActivity({
        ...activity,
        insulin_med: {
          ...defaultInsulinActivity.insulin_med,
          account_insulin_id: accountInsulinId,
        },
      })
    },
    [
      activity,
      updateActivity,
    ],
  )

  return (
    <Dropdown className={styles.container}>
      <DropdownSelect
        value={
          activity.insulin_med.account_insulin_id === defaultInsulinActivity.insulin_med.account_insulin_id
            ? ""
            : `${activity.insulin_med.account_insulin_id}`
        }
        onChange={onInsulinChange}
        required
        id={id}
      >
        {selectOptions.map(
          (selectOption) => (
            <DropdownOption
              key={selectOption.value}
              value={selectOption.value}
              disabled={selectOption.disabled}
            >
              {selectOption.children}
            </DropdownOption>
          ),
        )}
      </DropdownSelect>
      <DropdownCurrentSelection />
      <DropdownCaret />
    </Dropdown>
  )
}
