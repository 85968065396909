import { useCalculationsContext } from "../CalculationsContext"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileUnit } from "./TileUnit"
import { TileValue } from "./TileValue"
import {
  useReadableGlucoseUnit,
  useDisplayGlucoseValue,
} from "src/hooks"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"

interface Props {
  range: InsightRange;
}

export function GlucoseStandardDeviation(props: Props) {
  const { range } = props

  const glucoseUnit = useReadableGlucoseUnit()
  const displayGlucoseValue = useDisplayGlucoseValue()
  const { glucoseCalculations } = useCalculationsContext()
  const { standardDeviation } = glucoseCalculations[range] || {}

  const value = isNaN(standardDeviation) || standardDeviation === undefined
    ? "--"
    : displayGlucoseValue(standardDeviation)

  return (
    <>
      <TileTitle insightKey={InsightKey.GlucoseStandardDeviation} />
      <TileTimeSpan
        id={`${InsightKey.GlucoseStandardDeviation}-time-range`}
        range={range}
      />
      <TileValue
        id={`${InsightKey.GlucoseStandardDeviation}-value`}
      >
        {`±${value}`}
      </TileValue>
      <TileUnit id={`${InsightKey.GlucoseStandardDeviation}-unit`}>
        {glucoseUnit}
      </TileUnit>
    </>
  )
}
