import {
  CGMMenuItem,
  CGMMenuItemIconButton,
  CGMMenuItemLabel,
  CGMMenuItemSubtext,
  CGMMenuItemText,
  TrashCanOutlineIcon,
  useToasterContext,
} from "@tandemdiabetes/sugarmate-react-components"
import type { MouseEventHandler } from "react"
import {
  useCallback,
  useState,
} from "react"

import { RemoveDialog } from "src/components"
import { usePolyglot } from "src/contexts"
import { useDeleteSource } from "src/hooks"
import {
  RemoveSourceErrorToast,
  RemoveSourceSuccessToast,
} from "src/toasts"
import type { Source } from "src/types"
import { SourceType } from "src/types"

const polyglotPrefix = "pages.settings.data_source."

interface Props {
  source: Source;
}

// TODO make CGM button NOT a button
export function DataSourceComponent(props: Props) {
  const { source } = props

  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const deleteSource = useDeleteSource(source.id)

  const [
    removeDialogOpen,
    setRemoveDialogOpen,
  ] = useState<boolean>(false)

  const onRemoveClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      () => {
        void (async () => {
          try {
            await deleteSource()
            showToast(
              "remove-nightscout-source-success-toast",
              RemoveSourceSuccessToast,
            )
          } catch (e) {
            showToast(
              "remove-nightscout-source-error-toast",
              RemoveSourceErrorToast,
            )
          }
        })()
      },
      [
        deleteSource,
        showToast,
      ],
    )

  const onClick = useCallback(
    () => {
      setRemoveDialogOpen(true)
    },
    [setRemoveDialogOpen],
  )

  const isNightscout = source.source_type === SourceType.Nightscout

  const connection = source.error
    ? polyglot.t(`${polyglotPrefix}component.disconnected`)
    : polyglot.t(`${polyglotPrefix}component.connected`)

  const validity = source.error
    ? polyglot.t(`${polyglotPrefix}component.invalid`)
    : polyglot.t(`${polyglotPrefix}component.valid`)

  const subtext = polyglot.t(
    `${polyglotPrefix}component.subtext`,
    {
      connection,
      validity,
    },
  )

  return (
    <>
      <CGMMenuItem>
        <CGMMenuItemText
          id={isNightscout ? "nightscout-data-source" : "dexcom-data-source"}
        >
          <CGMMenuItemLabel source={isNightscout ? "nightscout" : "dexcom"} />
          <CGMMenuItemSubtext>
            {subtext}
          </CGMMenuItemSubtext>
        </CGMMenuItemText>
        <CGMMenuItemIconButton onClick={onClick}>
          <TrashCanOutlineIcon id="trash-icon" />
        </CGMMenuItemIconButton>
      </CGMMenuItem>
      <RemoveDialog
        open={removeDialogOpen}
        setOpen={setRemoveDialogOpen}
        remove={onRemoveClick}
        title={polyglot.t(`${polyglotPrefix}delete_dialog.title`)}
        removeCta={polyglot.t(`${polyglotPrefix}delete_dialog.delete_cta`)}
      >
        {polyglot.t(`${polyglotPrefix}delete_dialog.description`)}
      </RemoveDialog>
    </>
  )
}
