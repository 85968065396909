import { useCalculationsContext } from "../CalculationsContext"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileUnit } from "./TileUnit"
import { TileValue } from "./TileValue"
import { usePolyglot } from "src/contexts"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"
import { round } from "src/utils"

interface Props {
  range: InsightRange;
}

export function InsulinTotalBolus(props: Props) {
  const { range } = props

  const polyglot = usePolyglot()

  const { insulinCalculations } = useCalculationsContext()
  const { totalBolusUnits } = insulinCalculations[range] || {}
  const value = totalBolusUnits === undefined ?
    NaN :
    round(
      totalBolusUnits,
      3,
    )

  const unit = polyglot.t(
    "unit.units",
    isNaN(value) ? 0 : value,
  )

  return (
    <>
      <TileTitle insightKey={InsightKey.InsulinTotalBolus} />
      <TileTimeSpan
        id={`${InsightKey.InsulinTotalBolus}-time-range`}
        range={range}
      />
      <TileValue id={`${InsightKey.InsulinTotalBolus}-value`}>
        {isNaN(value) ? "--" : value.toLocaleString(navigator.language)}
      </TileValue>
      <TileUnit id={`${InsightKey.InsulinTotalBolus}-unit`}>
        {unit}
      </TileUnit>
    </>
  )
}
