import {
  Dropdown,
  DropdownCaret,
  DropdownCurrentSelection,
  DropdownOption,
  DropdownSelect,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"

import { defaultMedicationActivity } from "./defaultMedicationActivity"
import styles from "./MedicationFormNameSelect.module.scss"
import { useNameSelectOptions } from "./useNameSelectOptions"
import type { MedicationActivity } from "src/types"

interface Props {
  activity: MedicationActivity;
  updateActivity: (a: MedicationActivity) => void;
}

/*
 * renders a select element for choosing medication name
 */
export function MedicationFormNameSelect(props: Props) {
  const {
    activity,
    updateActivity,
  } = props

  // calculate the options for the select menu
  const selectOptions = useNameSelectOptions(activity)

  // save changes to account_med_id
  const onMedicationChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      // TODO this shouldn't be a number in the future
      const accountMedId = Number(e.target.value)

      updateActivity({
        ...activity,
        medication: {
          ...defaultMedicationActivity.medication,
          account_med_id: accountMedId,
        },
      })
    },
    [
      activity,
      updateActivity,
    ],
  )

  return (
    <Dropdown className={styles.container}>
      <DropdownSelect
        value={
          activity.medication.account_med_id === defaultMedicationActivity.medication.account_med_id
            ? ""
            : `${activity.medication.account_med_id}`
        }
        onChange={onMedicationChange}
        required
        id="medication-form-name-picker"
      >
        {selectOptions.map(
          (selectOption) => (
            <DropdownOption
              key={selectOption.value}
              value={selectOption.value}
              disabled={selectOption.disabled}
            >
              {selectOption.children}
            </DropdownOption>
          ),
        )}
      </DropdownSelect>
      <DropdownCurrentSelection />
      <DropdownCaret />
    </Dropdown>
  )
}
