import type { AxiosResponse } from "axios"
import dayjs from "dayjs"
import { detect } from "detect-browser"
import { useCallback } from "react"
import { post } from "src/api"
import type { GlucoseUnit } from "src/types"
import { UserDeviceType } from "src/types"
import { noticeError } from "src/utils"

interface Params {
  email: string;
  password: string;
  country: string;
  units: GlucoseUnit;
  firstname: string;
  lastname: string;
  dateOfBirth: string;
}

interface Response {
  status: string;
  code: string;
}

type CreateAccount = (params: Params) => Promise<AxiosResponse<Response>>

export function useCreateAccount(): CreateAccount {
  return useCallback(
    async (params: Params) => {
      const app_version = "3.0"
      const device_type = UserDeviceType.Web
      const locale = navigator.language
      const timezone = dayjs().utcOffset() / 60 // utc offset in hours

      const browser = detect()
      const device_version = browser
        ? `${browser.name} ${browser.version}`
        : "unknown"

      try {
        const response = await post<Response>({
          url: "/api/v2/devices",
          data: {
            app_version,
            device_type,
            device_version,
            locale,
            timezone,
            email: params.email,
            password: params.password,
            country: params.country,
            units: params.units,
            firstname: params.firstname,
            lastname: params.lastname,
            birthdate: params.dateOfBirth,
          },
        })

        return response
      } catch (e) {
        noticeError(
          e,
          "CreateAccount",
          {
            app_version,
            device_type,
            device_version,
            locale,
            timezone,
            email: params.email,
            country: params.country,
            units: params.units,
            firstname: params.firstname,
            lastname: params.lastname,
            dateOfBirth: params.dateOfBirth,
          },
        )

        throw e
      }
    },
    [],
  )
}
